import axiosInstance from '@/plugins/axios'

export default {
  postMassmail(subject, text) {
    const url = 'admin/tools/massmail'

    return axiosInstance.post(url, { subject, text })
      .then(response => response.data)
  },

  getIpBlacklist() {
    const url = 'admin/tools/blacklist'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getIpCheck(userId) {
    const url = `admin/tools/ip-check/${userId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getScoreAnalysis(accountId) {
    const url = `admin/tools/risk-score/${accountId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getLinkage(userId) {
    const url = `admin/tools/linkage/${userId}`

    return axiosInstance.get(url)
      .then(response => response.data)
  },
}
