<template>
  <b-overlay
    :show="sending"
    rounded="sm"
  >
    <b-card>
      <div class="mail">
        <div class="logo-container">
          <e8-logo class="logo" />
        </div>
        <div class="content">
          <p style="font-size: 22px; margin: 24px 0;">
            <strong><span style="font-family: helvetica, sans-serif;">Dear John Doe,</span></strong>
          </p>
          <editor-content
            :editor="editor"
            class="editor"
          />
          <hr>
          <div style="text-align: left; word-wrap: break-word;">
            <p style="margin: 24px 0;">
              Not sure why you received this email? Please <a
                href="mailto:support@e8funding.com"
                target="_blank"
                rel="noopener"
              >let us know</a>.
            </p>
            <p>Trade well,<br>E8Funding.com</p>
          </div>
        </div>
        <p style="font-size: 12px;  text-align: center;">
          Use of our service and website is subject to our <a
            href="https://e8funding.com/terms-conditions/"
            target="_blank"
            rel="noopener"
          >Terms of Use</a> and <a
            href="https://e8funding.com/terms-conditions/"
            target="_blank"
            rel="noopener"
          >Privacy Policy</a>.
        </p>
      </div>
      <b-form-group
        label="Subject"
      >
        <validation-provider
          #default="{ errors }"
          name="Subject*"
          rules="required"
        >
          <b-form-input
            v-model="subject"
            placeholder="Subject"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-button
        variant="primary"
        class="mb-3"
        @click="onSend"
      >
        Send emails
      </b-button>
    </b-card>
  </b-overlay>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import {
  BButton, BCard, BOverlay, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import E8Logo from '@/components/Logo.vue'
import { required } from '@/plugins/validations'
import ToolsAPI from '@/api/tools'

export default {
  components: {
    EditorContent,
    E8Logo,
    BButton,
    BCard,
    ValidationProvider,
    BOverlay,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      editor: null,
      content: 'Here you can insert your text',
      sending: false,
      subject: '',
      required,
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.content,
      extensions: [
        StarterKit,
        Paragraph.configure({
          HTMLAttributes: {
            style: 'margin: 24px 0;',
          },
        }),
      ],
      onUpdate: () => {
        this.content = this.editor.getHTML()
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    onSend() {
      this.$bvModal
        .msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.sending = true
            ToolsAPI.postMassmail(this.subject, this.content).then(() => {
              this.sending = false
              this.content = ''
              this.subject = ''
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Email',
                  icon: 'SendIcon',
                  text: 'Email was sent successfully',
                  variant: 'success',
                },
              })
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
  .mail {
    background-color: #eceff1;
    color:rgb(98, 98, 98);
    font-family: helvetica, sans-serif;
    font-size:14px;
    padding: 40px 0;
    margin-bottom: 20px;
    .logo-container {
      margin: 0 auto;
      min-width: 320px;
      max-width: 600px;
      text-align: center;
      .logo{
        max-width: 60px;
        margin: 30px auto;
      }
    }
    .content{
      margin: 0 auto;
      background-color: #fff;
      padding: 40px;
      border-radius: 5px;
      min-width: 320px;
      max-width: 600px;
      .editor{
        border: 1px solid #e5e5e5;
        min-height: 50px;
      }
    }
  }
</style>
